import ClientModel from "./models/Client";
import ConnectionBase from "../Connection";
import ConnectionInit from "./models/ConnectionInit";
import Constraints from "./models/Constraints";
import Guard from "../core/Guard";
import PromiseCompletionSource from "../core/PromiseCompletionSource";
import Reactive from "../core/Reactive";

import DispatchQueue from "../core/DispatchQueue";
import Log from "../logging/Log";
import EventLogger from "../event/Logger";
import EventOwnerAsync from "../core/EventOwnerAsync";

import Message from "./models/Message";

import LocalMedia from "../media/LocalMedia";
import LocalMediaOptions from "../models/LocalMediaOptions";
import LocalTrackPriority from "../models/LocalTrackPriority";

import Track from "../models/Track";
import TrackType from "../media/models/TrackType";
import StepType from "./models/StepType";
import MediaEvent from "./models/MediaEvent";
import MediaType from "../media/models/MediaType";

import StatisticConnection from "../models/StatisticConnection";
import StatisticAudio from "../models/StatisticAudio";
import StatisticVideo from "../models/StatisticVideo";

import Bitrate from "../core/Bitrate";
import Framerate from "../core/Framerate";

import VideoUtility from "../core/VideoUtility";
import {
  VideoOriginFrameRateDisplayMin,
  VideoOriginPixelCountDisplayMin,
  VideoOriginJitterDisplayLow,
  VideoOriginJitterDisplayMedium,
  VideoOriginJitterDisplayHigh,
  VideoOriginPacketLossDisplayLow,
  VideoOriginPacketLossDisplayMedium,
  VideoOriginPacketLossDisplayHigh,
  AudioOriginJitterThresholdDisplayLow,
  AudioOriginJitterThresholdDisplayMedium,
  AudioOriginJitterThresholdDisplayHigh,
  AudioOriginPacketLossThresholdDisplayLow,
  AudioOriginPacketLossThresholdDisplayMedium,
  AudioOriginPacketLossThresholdDisplayHigh,
  VideoOriginFrameRateUserMin,
  VideoOriginPixelCountUserMin,
  VideoOriginJitterUserLow,
  VideoOriginJitterUserMedium,
  VideoOriginJitterUserHigh,
  VideoOriginPacketLossUserLow,
  VideoOriginPacketLossUserMedium,
  VideoOriginPacketLosUserHigh,
  AudioOriginJitterThresholdUserLow,
  AudioOriginJitterThresholdUserMedium,
  AudioOriginJitterThresholdUserHigh,
  AudioOriginPacketLossThresholUserLow,
  AudioOriginPacketLossThresholdUserMedium,
  AudioOriginPacketLossThresholdUserHigh,
} from "./models/TenantSettings";
import DegradationLevel from "./models/DegradationLevel";
import VideoTrack from "../media/VideoTrack";
import Utility from "../core/Utility";
import IceCandidate from "../models/IceCandidate";

const qualityLimitationReasons: Map<String, number> = new Map([
  ["none", 1000],
  ["bandwidth", 2000],
  ["cpu", 3000],
  ["other", 4000],
]);
const qualityLimitationReasonNone = 1000;
const qualityLimitationReasonUs = 100; // SDK is making the constraint adjustment
const qualityLimitationReasonDecrease = 10;
const qualityLimitationReasonIncrease = 20;
const qualityLimitationReasonBitrate = 1;
const qualityLimitationReasonFrameRate = 3;
const qualityLimitationReasonResolution = 5;

// Default

const medianIOSFrameRate = 15;
const defaultMinBitrate = 50;
const defaultMinFrameRateUser = 10;
const defaultMinFrameRateUserIOS = 10;
const defaultMinFrameRateDisplay = 3;
const defaultMinPixelCountDisplay = 921600; // 1280x720 - For screenshare, don't go below this resolution, otherwise it's unusuable especially when sharing text
const defaultScalingBackoffUser = 3;
const defaultScalingBackoffDisplay = 5;
const defaultIceRestartBackoff = 5;

const trackTypes: TrackType[] = ["audio", "video"];
/*
 * Audio impairment default statuses.
 */
export enum AudioHealthIssues {
  JITTERHIGH = "Jitter - High",
  JITTERMEDIUM = "Jitter - Medium",
  JITTERLOW = "Jitter - Low",
  PACKETLOSSHIGH = "Packet Loss - High",
  PACKETLOSSMEDIUM = "Packet Loss - Medium",
  PACKETLOSSLOW = "Packet Loss - Low",
  ROUNDTRIPTIMEHIGH = "Round Trip Time - High",
  ROUNDTRIPTIMEMEDIUM = "Round Trip Time - Medium",
  ROUNDTRIPTIMELOW = "Round Trip Time - Low",
}
export default class Connection extends ConnectionBase<Message> {
  private readonly _audioLevelInterval?: number;
  private readonly _client: ClientModel;
  private readonly _mediaChannel: RTCDataChannel;
  private readonly _mediaNotificationQueue = new DispatchQueue();
  private readonly _mediaOptions: LocalMediaOptions;
  private readonly _mediaRejected = new EventOwnerAsync<MediaEvent>();
  private readonly _mediaReplaced = new EventOwnerAsync<MediaEvent>();
  private readonly _mediaType: MediaType;
  private readonly _onAudioTrackStreamBound: () => Promise<void>;
  private readonly _onAudioTrackStreamUnbound: () => void;
  private readonly _onMediaChannelClose: () => void;
  private readonly _onMediaChannelClosing: () => void;
  private readonly _onMediaChannelError: (ev: any) => void;
  private readonly _onMediaChannelMessage: (ev: MessageEvent<any>) => any;
  private readonly _onMediaChannelOpen: () => void;
  private readonly _onMediaStateChanged: () => Promise<void>;
  private readonly _onVideoTrackFrameSizeChanged: () => void;
  private readonly _onVideoTrackStreamBound: () => Promise<void>;
  private readonly _onVideoTrackStreamUnbound: () => void;
  private readonly _replicationCount?: number;
  private readonly _rttHistory: Array<number> = new Array();

  private readonly _transceivers = new Map<TrackType, Track>();

  // Video impairment default thresholds
  private _availableBitrateThreshold: number = 100;
  private _availableBitrateFrameRateRecoveryThreshold: number = 200;
  private _bitrateSteadyThreshold: number = 3;
  private _bitrateIncreasesThreshold: number = 3;
  private _frameRateDegradedThreshold: number = 0.5;
  private _jitterHighThreshold: number = 160;
  private _jitterLowThreshold: number = 80;
  private _jitterMediumThreshold: number = 120;
  private _jitterRttHighThreshold: number = 120;
  private _jitterRttLowThreshold: number = 40;
  private _jitterRttMediumThreshold: number = 80;
  private _minBitrateStep: number = 200;
  private _nackHighThreshold: number = .15;
  private _nackLowThreshold: number = .075;
  private _nackMediumThreshold: number = .1;
  private _packetLossHighThreshold: number = 0.2;
  private _packetLossLowThreshold: number = 0.05;
  private _packetLossMediumThreshold: number = 0.1;
  private _pliThresholdHigh: number = 15;
  private _pliThresholdLow: number = 4;
  private _pliThresholdMedium: number = 8;
  private _recoveryThreshold: number = 7;
  private _rttAverageThreshold: number = 150;
  private _rttHistorySize: number = 10;
  private _rttHighThreshold: number = 400;
  private _rttLowThreshold: number = 300;
  private _rttMediumThreshold: number = 350;
  private _rttOffThreshold: number = 500;

  // ICE 
  private _currentCandidateIp: string = null;
  private _currentCandidateNetworkAdapterType: string = null;
  private _currentCandidateNetworkType: string = null;
  private _iceRestartThreshold: number = 2;
  private _iceRestartBackoff: number = defaultIceRestartBackoff;
  private _rttUnhealthyCount: number = 0;


  private _answerUpdated: PromiseCompletionSource<Message>;
  private _bitrateIncreases: number = 0;
  private _bitrateSteady: number = 0;
  private _clientConstraints: Constraints = null;
  private _currentLevel: DegradationLevel = "high";
  private _currentRampUpSeconds = 0;
  private _customBandwidthAdaptationEnabled = true;
  private _degradationPreference: RTCDegradationPreference = "balanced";
  private _encodingEnabled = true;
  private _estimatedConstraints: Constraints = null;
  private _healthyChecks = 0;
  private _maxAudioBitrate = 96;
  private _maxBitrate = 2200;
  private _maxFrameRate = 25;
  private _maxHeight = 1080;
  private _maxPixelCount = 2073600;
  private _maxWidth = 1920;
  private _media: LocalMedia = null;
  private _mediaChannelBytesReceived = 0;
  private _mediaChannelBytesSent = 0;
  private _minAudioBitrate = 10;
  private _minBitrate = defaultMinBitrate;
  private _minFrameRate = defaultMinFrameRateUser;
  private _minFrameRateSevereDegradation = 5;
  private _minHeight = 160;
  private _minPixelCount = 38400;
  private _minWidth = 240;
  private _priorityAudio: LocalTrackPriority = "high";
  private _priorityVideo: LocalTrackPriority = "high";
  private _rampUpSeconds = 5;
  private _rampUpSecondsMobile = 10;
  private _rampUpStartBitrate: number = Utility.isMobileBrowser() ? 600 : 1000;
  // On mobile, ramp up the pixel count starting at 960x540. Helps with lagging frame rate during the start of the call.
  private _rampUpStartPixelCount: number = Utility.isMobileBrowser() ? 518400 : this._maxPixelCount;
  private _rampedUp = false;
  private _rampedUpMobile = false;
  private _recoveryTime: number = 0;
  private _scalingBackoff: number = 0;
  private _serverConstraints: Constraints = null;
  private _unhealthyChecks = 0;

  private _statsBatchAudio: Array<StatisticAudio> = [];
  private _statsBatchVideo: Array<StatisticVideo> = [];
  //TODO: from setting, or at least a shared constant
  private _currentStatCount = 1;
  private _lastPairState: string = null;
  private _statsSendingInterval = 1; //how often to send the stats. Ex: if stats are gathered every second, and interval is 10, it will send what it has every 10th reading
  private _statsPollingInterval = 1; //how often to save the stats. Ex: if stats are gathered every second, and inteval is 5, it will save every 5th reading

  //TODO: these should belong on an aggregate Track object instead of in arrays like all the other variables. It'll just be wrong sometimes for now
  private _bitrateAudio = new Bitrate();
  private _bitrateVideo = new Bitrate();
  private _framerateVideo = new Framerate();

  public get audioBitrateMax(): number {
    return this._clientConstraints?.audioBitrateMax;
  }
  public get currentLevel(): DegradationLevel {
    return this._currentLevel;
  }
  public get currentLevelAudioBitrate(): number {
    return this._clientConstraints?.audioBitrateMax / this._maxAudioBitrate;
  }
  public get currentLevelVideoBitrate(): number {
    return this._clientConstraints?.videoBitrateMax / this._maxBitrate;
  }
  public get currentLevelFrameRate(): number {
    return this._clientConstraints?.videoFrameRateMax / this._maxFrameRate;
  }
  public get currentLevelPixelCount(): number {
    return this._clientConstraints?.videoPixelCountMax / this._maxPixelCount;
  }
  public get media(): LocalMedia {
    return this._media;
  }
  public get mediaRejected(): EventOwnerAsync<MediaEvent> {
    return this._mediaRejected;
  }
  public get mediaReplaced(): EventOwnerAsync<MediaEvent> {
    return this._mediaReplaced;
  }
  public get mediaType(): MediaType {
    return this._mediaType;
  }
  public get priorityAudio(): LocalTrackPriority {
    return this._priorityAudio;
  }
  public get priorityVideo(): LocalTrackPriority {
    return this._priorityVideo;
  }
  public get videoBitrateMax(): number {
    return this._clientConstraints?.videoBitrateMax;
  }
  public get videoPixelCountMax(): number {
    return this._clientConstraints?.videoPixelCountMax ?? this._maxPixelCount;
  }

  public get videoFramerateMin(): number {
    return this._minFrameRate;
  }
  public get videoHeightMin(): number {
    return this._minHeight;
  }
  public get videoWidthMin(): number {
    return this._minWidth;
  }
  public get videoFramerateMax(): number {
    return this._maxFrameRate;
  }
  public get videoHeightMax(): number {
    return this._maxHeight;
  }
  public get videoWidthMax(): number {
    return this._maxWidth;
  }

  public constructor(init: ConnectionInit) {
    super({
      attendeeId: init.attendeeId,
      eventLogger: new EventLogger(init.apiClient, "OriginConnection", init.attendeeId, init.meetingId, init.clusterId),
      iceRestartEnabled: init.iceRestartEnabled,
      iceReconnectionTimeout: init.iceReconnectionTimeout,
      meetingId: init.meetingId,
      redAudioEnabled: init.redAudioEnabled,
      turnRequired: init.turnRequired,
      turnSession: init.turnSession,
      type: `Origin (${init.mediaType})`,
    });
    this._audioLevelInterval = init.audioLevelInterval;
    this._client = init.client;
    this._mediaOptions = init.mediaOptions;
    this._mediaType = init.mediaType;
    this._replicationCount = init.replicationCount;
    this._statsBatchAudio = [];
    this._statsBatchVideo = [];
    this._bitrateAudio = new Bitrate();
    this._bitrateVideo = new Bitrate();
    this._framerateVideo = new Framerate();

    this._customBandwidthAdaptationEnabled = this._mediaOptions.customBandwidthAdaptationEnabled ??= true;
    this._degradationPreference = this._mediaOptions.degradationPreference ??= this._mediaType === "display" ? "maintain-resolution" : "maintain-framerate";

    // Only ramp up for user media. Display media should start at the best quality possible
    if (this._mediaType === "user" && this._customBandwidthAdaptationEnabled) {
      this._currentLevel = "medium";
    } else {
      this._minPixelCount = defaultMinPixelCountDisplay;
      this._rampUpStartBitrate = this._maxBitrate;
      this._rampUpStartPixelCount = this._maxPixelCount;
    }

    if (init.room) {
      const room = init.room;
      if (this._mediaType === "display") {
        if (room.minVideoBitrate) this._minBitrate = room.minVideoBitrate;
        if (room.minVideoFramerateDisplay) this._minFrameRate = room.minVideoFramerateDisplay;
        if (room.minVideoPixelCountDisplay && room.minVideoPixelCountDisplay > this._minPixelCount) this._minPixelCount = room.minVideoPixelCountDisplay;
        if (room.maxAudioDisplay) this._maxAudioBitrate = room.maxAudioDisplay;
        if (room.minAudioBitrate) this._minAudioBitrate = room.minAudioBitrate;
      } else if (this._mediaType === "user") {
        if (room.minVideoBitrate) this._minBitrate = room.minVideoBitrate;
        if (room.minVideoFramerateUser) this._minFrameRate = Utility.isIOS() ? defaultMinFrameRateUserIOS : room.minVideoFramerateUser;
        if (room.minVideoPixelCountUser) this._minPixelCount = room.minVideoPixelCountUser;
        if (room.minVideoHeightUser) this._minHeight = room.minVideoHeightUser;
        if (room.minVideoWidthUser) this._minHeight = room.minVideoWidthUser;
        if (room.maxVideoFramerateUser) this._maxFrameRate = room.maxVideoFramerateUser;
        if (room.maxVideoHeightUser) this._maxHeight = room.maxVideoHeightUser;
        if (room.maxVideoWidthUser) this._maxWidth = room.maxVideoWidthUser;
        if (room.maxAudioBitrate) this._maxAudioBitrate = room.maxAudioBitrate;
        if (room.minAudioBitrate) this._minAudioBitrate = room.minAudioBitrate;
      }
      if (room.maxVideoBitrate) this._maxBitrate = room.maxVideoBitrate;
    }

    //TODO: change to eventLogger
    if (init.tenantSettings && init.tenantSettings.length > 0) {
      const settings = init.tenantSettings;
      settings.forEach((setting) => {
        try {
          if (this._mediaType === "display") {
            switch (setting.settingName) {
              case VideoOriginFrameRateDisplayMin:
                if (this._minFrameRate == 0) {
                  this._minFrameRate = Number.parseFloat(setting.settingValue);
                  Log.debug(`Set minFrametRate from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case VideoOriginPixelCountDisplayMin:
                if (this._minPixelCount == 0) {
                  this._minPixelCount = Number.parseInt(setting.settingValue);
                  Log.debug(`Set minPixelCount from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case VideoOriginJitterDisplayLow: {
                this._jitterLowThreshold = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterDisplayMedium: {
                this._jitterMediumThreshold = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterDisplayHigh: {
                this._jitterHighThreshold = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossDisplayLow: {
                this._packetLossLowThreshold = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossDisplayMedium: {
                this._packetLossMediumThreshold = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossDisplayHigh: {
                this._packetLossHighThreshold = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
            }
          } else if (this._mediaType === "user") {
            switch (setting.settingName) {
              case VideoOriginFrameRateUserMin:
                if (this._minFrameRate == 0) {
                  this._minFrameRate = Number.parseFloat(setting.settingValue);
                  Log.debug(`Set minFrametRate from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case VideoOriginPixelCountUserMin:
                if (this._minPixelCount == 0) {
                  this._minPixelCount = Number.parseInt(setting.settingValue);
                  Log.debug(`Set minPixelCount from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                }
                break;
              case VideoOriginJitterUserLow: {
                this._jitterLowThreshold = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterUserMedium: {
                this._jitterMediumThreshold = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginJitterUserHigh: {
                this._jitterHighThreshold = Number.parseInt(setting.settingValue);
                Log.debug(`Set jitterHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossUserLow: {
                this._packetLossLowThreshold = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossLow from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLossUserMedium: {
                this._packetLossMediumThreshold = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossMedium from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
              case VideoOriginPacketLosUserHigh: {
                this._packetLossHighThreshold = Number.parseFloat(setting.settingValue);
                Log.debug(`Set packetLossHigh from TenantSetting: Value=${setting.settingValue}, MediaType=${this._mediaType}`);
                break;
              }
            }
          }

          switch (setting.settingName) {
            case "ORIGIN:BITRATE:MIN":
              if (this._minBitrate == 0) {
                this._minBitrate = Number.parseInt(setting.settingValue);
                Log.debug(`Set minBitrate from TenantSetting: Value=${setting.settingValue}`);
              }
            case "ORIGIN:STATISTIC:POLLINGINTERVAL":
              this._statsPollingInterval = Number.parseInt(setting.settingValue);
              Log.debug(`Set stats pollingInterval from TenantSetting: Value=${setting.settingValue}`);
              break;
            case "ORIGIN:STATISTIC:BATCHSIZE":
              this._statsSendingInterval = Number.parseInt(setting.settingValue);
              Log.debug(`Set statsBatchSize from TenantSetting: Value=${setting.settingValue}`);
              break;
            case "ORIGIN:RAMPUP:SECONDS":
              this._rampUpSeconds = Number.parseInt(setting.settingValue);
              this._rampUpSecondsMobile = this._rampUpSeconds * 2;
              Log.debug(`Set rampUpSeconds from TenantSetting: Value=${setting.settingValue}`);
              break;
          }
        } catch (err: any) {
          //TODO: change to eventLogger
          Log.error(`Error parsing TenantSetting: ${setting.settingName}=${setting.settingValue} Type=${setting.settingType}`, err);
        }
      });
    }

    // Defaults if values are not set by settings

    if (this._minBitrate == 0) this._minBitrate = defaultMinBitrate;
    if (this._minFrameRate == 0) this._minFrameRate = this._mediaType == "display" ? defaultMinFrameRateDisplay : Utility.isIOS() ? defaultMinFrameRateUserIOS : defaultMinFrameRateUser;
    if (this._minPixelCount == 0) this._minPixelCount = this._mediaType == "display" ? 1280 * 720 : this._minHeight * this._minWidth;
    if (this._jitterHighThreshold == 0) this._jitterHighThreshold = 120;
    if (this._jitterMediumThreshold == 0) this._jitterMediumThreshold = 80;
    if (this._jitterMediumThreshold == 0) this._jitterLowThreshold = 30;
    if (this._packetLossHighThreshold == 0) this._packetLossHighThreshold = 0.2;
    if (this._packetLossMediumThreshold == 0) this._packetLossMediumThreshold = 0.1;
    if (this._packetLossMediumThreshold == 0) this._packetLossLowThreshold = 0.03;

    this._onAudioTrackStreamBound = this.onAudioTrackStreamBound.bind(Reactive.wrap(this));
    this._onAudioTrackStreamUnbound = this.onAudioTrackStreamUnbound.bind(Reactive.wrap(this));
    this._onMediaChannelClose = this.onMediaChannelClose.bind(Reactive.wrap(this));
    this._onMediaChannelClosing = this.onMediaChannelClosing.bind(Reactive.wrap(this));
    this._onMediaChannelError = this.onMediaChannelError.bind(Reactive.wrap(this));
    this._onMediaChannelMessage = this.onMediaChannelMessage.bind(Reactive.wrap(this));
    this._onMediaChannelOpen = this.onMediaChannelOpen.bind(Reactive.wrap(this));
    this._onMediaStateChanged = this.onMediaStateChanged.bind(Reactive.wrap(this));
    this._onVideoTrackFrameSizeChanged = this.onVideoTrackFrameSizeChanged.bind(Reactive.wrap(this));
    this._onVideoTrackStreamBound = this.onVideoTrackStreamBound.bind(Reactive.wrap(this));
    this._onVideoTrackStreamUnbound = this.onVideoTrackStreamUnbound.bind(Reactive.wrap(this));

    this._mediaChannel = this.connection.createDataChannel("media");
    this._mediaChannel.binaryType = "arraybuffer";

    for (const trackType of trackTypes) {
      const trk = new Track();
      trk.status = "enabled";
      trk.mediaType = this._mediaType;
      trk.trackType = trackType;
      trk.index = this._transceivers.size;
      trk.priority = "high";
      trk.spatialLayerIndex = 0;
      trk.temporalLayerIndex = 0;
      trk.statAudio = new StatisticAudio();
      trk.statVideo = new StatisticVideo();
      trk.statPair = new StatisticConnection();
      trk.bitrateAudio = new Bitrate();
      trk.bitrateVideo = new Bitrate();
      trk.framerateVideo = new Framerate();

      const transceiver = this.connection.addTransceiver(trackType, {
        direction: "inactive",
      });
      trk.transceiver = transceiver;
      this._transceivers.set(trackType, trk);
      //this._senders.set(trackType, transceiver.sender);
      //this._senderStats.set(trackType, new TrackStats());

      if (trackType === "audio") {
        this.setPreferredAudioCodecsOrigin(transceiver);
      }
    }

    this.attachEventHandlers();
  }

  private getSenderEncodings(parameters: RTCRtpSendParameters): RTCRtpEncodingParameters[] {
    const encodings = parameters.encodings ?? [{}];
    parameters.encodings = encodings;
    return encodings;
  }

  private onAudioTrackStreamBound(): Promise<void> {
    const trackStream = this._media.audioTrack.stream;
    return this.eventQueue.dispatch(async () => {
      await this.tryReplaceSenderTrack("audio", trackStream);
      await this.updateTransceiverDirections();
    });
  }

  private onAudioTrackStreamUnbound(): void {
    void this.eventQueue.dispatch(async () => {
      if (this.isTerminated) return;
      await this.tryReplaceSenderTrack("audio", null);
      await this.updateTransceiverDirections();
    });
  }

  private onMediaChannelClose(): void {
    const message = `Origin ${this.mediaType} connection media channel has closed.`;
    void this.eventLogger.debug("onMediaChannelClose", message);
  }

  private onMediaChannelClosing(): void {
    const message = `Origin ${this.mediaType} connection media channel is closing.`;
    void this.eventLogger.debug("onMediaChannelClosing", message);
  }

  private onMediaChannelError(ev: RTCErrorEvent): void {
    const errDetail = ev.error?.errorDetail ?? ev.error ?? "";
    const message = `Origin ${this.mediaType} connection media channel has failed. ${errDetail}`.trimEnd();
    void this.eventLogger.debug("onMediaChannelError", message);
  }

  //TODO: in-progess deprecating
  private onMediaChannelMessage(ev: MessageEvent<any>): void {
    //const arrayBuffer = <ArrayBuffer>ev.data;
    //this._mediaChannelBytesReceived += arrayBuffer.byteLength;
    //const buffer = new Uint8Array(arrayBuffer);
    //if (buffer.length == 0) return;
    //const payloadType = buffer[0];
    //if (payloadType == 0) {
    //  if (buffer.length == 1) return;
    //  const audioLevel = buffer[1] / 255;
    //  this._media?.audioTrack?.updateLevel(audioLevel);
    //}
  }

  private onMediaChannelOpen(): void {
    const message = `Origin ${this.mediaType} connection media channel has opened.`;
    void this.eventLogger.debug("onMediaChannelOpen", message);
  }

  private onMediaStateChanged(): Promise<void> {
    return this.eventQueue.dispatch(async () => {
      await this.updateMediaBindings();
      await this.updateTransceiverDirections();
    });
  }

  private onVideoTrackFrameSizeChanged(): void {
    void this.eventQueue.dispatch(async () => {
      await this.updateSenderParametersVideo();
    });
  }

  private onVideoTrackStreamBound(): Promise<void> {
    const trackStream = this._media.videoTrack.stream;
    return this.eventQueue.dispatch(async () => {
      await this.tryReplaceSenderTrack("video", trackStream);
      await this.updateTransceiverDirections();
    });
  }

  private onVideoTrackStreamUnbound(): void {
    void this.eventQueue.dispatch(async () => {
      if (this.isTerminated) return;
      await this.tryReplaceSenderTrack("video", null);
      await this.updateTransceiverDirections();
    });
  }

  private async tryReplaceSenderTrack(trackType: TrackType, track: MediaStreamTrack): Promise<boolean> {
    const sender = this.getSender(trackType);
    try {
      if (this.state == "closed") return false;
      if (sender.track == track) return false;
      await sender.replaceTrack(track);
      if (trackType == "video")
        this.sendNotification({
          type: "frameRateUpdated",
          frameRate: track?.getSettings()?.frameRate ?? null,
        });
      return true;
    } catch (error: any) {
      const trkDetail = track == null ? "true" : "false";
      void this.eventLogger.warning(
        <Error>error,
        "tryReplaceSenderTrack",
        `Could not replace origin ${this._mediaType} ${trackType} sender track (null:${trkDetail}).`
      );
      return false;
    }
  }

  private trySetTransceiverDirection(trackType: TrackType, direction: RTCRtpTransceiverDirection): boolean {
    const transceiver = this.getTransceiver(trackType);
    try {
      if (this.state == "closed") return false;
      if (transceiver.direction == direction || transceiver.direction == "stopped" || (transceiver as any).stopped /* legacy */) return false;
      transceiver.direction = direction;
      return true;
    } catch (error: any) {
      void this.eventLogger.warning(
        <Error>error,
        "trySetTransceiverDirection",
        `Could not set origin ${this._mediaType} ${trackType} transceiver direction to ${direction}.`
      );
      return false;
    }
  }

  // TODO: Update logic to update bitrate constraints based on isFeatured flag
  private async updateConstraints(constraints: Constraints): Promise<void> {
    void this.eventLogger.debug("updateConstraints", `Updating origin ${this._mediaType} constraints...`, null, {
      audioBitrateMax: constraints.audioBitrateMax,
      videoBitrateMax: constraints.videoBitrateMax,
      videoFrameRateMax: constraints.videoFrameRateMax,
      videoPixelCountMax: constraints.videoPixelCountMax,
    });

    if (this._estimatedConstraints == null) {
      this._estimatedConstraints = {
        audioBitrateMax: constraints.audioBitrateMax,
        videoBitrateMax: this._currentLevel === "medium" ? Math.min(this._rampUpStartBitrate, this._maxBitrate / 2) : this._maxBitrate,
        videoFrameRateMax: this._maxFrameRate,
        videoPixelCountMax: this._currentLevel === "medium" ? this._rampUpStartPixelCount : this._maxPixelCount,
      } as Constraints;
    }
    if (this._clientConstraints == null) {
      this._clientConstraints = {
        audioBitrateMax: constraints.audioBitrateMax,
        videoBitrateMax: this._currentLevel === "medium" ? Math.min(this._rampUpStartBitrate, this._maxBitrate / 2) : this._maxBitrate,
        videoFrameRateMax: this._maxFrameRate,
        videoPixelCountMax: this._currentLevel === "medium" ? this._rampUpStartPixelCount : this._maxPixelCount,
      } as Constraints;
    }

    if (this._serverConstraints == null) {
      this._serverConstraints = constraints;
    } else {
      Log.debug(`Updating server constraints: ${JSON.stringify(constraints)}`);
      this._serverConstraints.audioBitrateMax = constraints.audioBitrateMax;
      this._serverConstraints.videoBitrateMax = Math.max(this._minBitrate, constraints.videoBitrateMax);
      this._serverConstraints.videoFrameRateMax = Math.max(this._minFrameRate, constraints.videoFrameRateMax);
      this._serverConstraints.videoPixelCountMax = Math.max(this._minPixelCount, constraints.videoPixelCountMax);

      this._estimatedConstraints = this._serverConstraints;

      // If we are in a degraded state, only update the constraints from the server if the values are lower than what is currently set
      if (this._currentLevel != "high") {
        this._clientConstraints.audioBitrateMax = Math.min(this._clientConstraints.audioBitrateMax, constraints.audioBitrateMax);
        this._clientConstraints.videoBitrateMax = Math.min(this._clientConstraints.videoBitrateMax, constraints.videoBitrateMax);
        this._clientConstraints.videoFrameRateMax = Math.min(this._clientConstraints.videoFrameRateMax, constraints.videoFrameRateMax);
        this._clientConstraints.videoPixelCountMax = Math.min(this._clientConstraints.videoPixelCountMax, constraints.videoPixelCountMax);
      } else {
        // If we're not in a degraded state, update all of the values to the new server values
        this._clientConstraints.audioBitrateMax = this._serverConstraints.audioBitrateMax;
        this._clientConstraints.videoBitrateMax = this._serverConstraints.videoBitrateMax;
        this._clientConstraints.videoFrameRateMax = this._serverConstraints.videoFrameRateMax;
        this._clientConstraints.videoPixelCountMax = this._serverConstraints.videoPixelCountMax;
      }
    }
    await this.updateSenderParameters();
  }

  private async updateMediaBindings(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", this._media?.audioTrack?.stream ?? null);
    await this.tryReplaceSenderTrack("video", this._media?.videoTrack?.stream ?? null);
  }

  private async updateSenderParametersAudio(): Promise<void> {
    const sender = this._transceivers.get("audio").transceiver.sender;
    const parameters = sender.getParameters();
    const encodings = this.getSenderEncodings(parameters);
    if (encodings && encodings.length > 0) {
      if (this._clientConstraints?.audioBitrateMax) {
        const maxBitrate = this._clientConstraints.audioBitrateMax * 1000;
        if (encodings[0].maxBitrate != maxBitrate) {
          //void this.eventLogger.debug("updateSenderParametersAudio", "Setting origin ${this._mediaType} audio max bitrate...", null, {
          //  maxBitrate: maxBitrate,
          //});
          encodings[0].maxBitrate = maxBitrate;
        }
      }
      encodings[0].priority = this._priorityAudio;
      try {
        await sender.setParameters(parameters);
      } catch (error: any) {
        void this.eventLogger.debug(<Error>error, "updateSenderParametersAudio", `Could not set origin ${this._mediaType} audio sender parameters.`);
      }
      Log.debug(`Sender Parameter Update: Enabled=${this._encodingEnabled}, Bitrate=${encodings[0].maxBitrate}, Priority=${encodings[0].priority}`);
    }
  }

  private async updateSenderParametersVideo(): Promise<void> {
    const sender = this._transceivers.get("video").transceiver.sender;
    const parameters = sender.getParameters();

    // Can't disable this so we tell WebRTC not to adjust resolution (best effort) - try to prevent PLIs from server
    parameters.degradationPreference = this._degradationPreference;

    const encodings = this.getSenderEncodings(parameters);
    if (encodings && encodings.length > 0) {
      if (this._clientConstraints?.videoBitrateMax) {
        const maxBitrate = Math.round(this._clientConstraints.videoBitrateMax * 1000);
        if (encodings[0].maxBitrate != maxBitrate) {
          void this.eventLogger.debug("updateSenderParametersVideo", `Setting origin ${this._mediaType} video max bitrate...`, null, {
            maxBitrate: maxBitrate,
          });
          encodings[0].maxBitrate = maxBitrate;
        }
      }
      if (this._clientConstraints?.videoFrameRateMax) {
        const maxFrameRate = this._clientConstraints.videoFrameRateMax;
        if (encodings[0].maxFramerate != maxFrameRate) {
          void this.eventLogger.debug("updateSenderParametersVideo", `Setting origin ${this._mediaType} video max frame rate...`, null, {
            maxFramerate: maxFrameRate,
          });
          encodings[0].maxFramerate = maxFrameRate;
        }
      }
      if (this._clientConstraints?.videoPixelCountMax) {
        const settings = sender.track?.getSettings();
        if (settings && settings.width && settings.height) {
          const scaleResolutionDownBy = Math.max(1, Math.sqrt((settings.width * settings.height) / this._clientConstraints.videoPixelCountMax));
          if (encodings[0].scaleResolutionDownBy != scaleResolutionDownBy) {
            void this.eventLogger.debug("updateSenderParametersVideo", `Scaling down origin ${this._mediaType} video resolution...`, null, {
              scaleResolutionDownBy: scaleResolutionDownBy,
              videoWidth: settings.width,
              videoHeight: settings.height,
            });
            encodings[0].scaleResolutionDownBy = scaleResolutionDownBy;
          }
        }
      }

      encodings[0].active = this._encodingEnabled;
      encodings[0].priority = this._priorityVideo;
      encodings[0].networkPriority = this._priorityVideo;

      try {
        await sender.setParameters(parameters);
      } catch (error: any) {
        void this.eventLogger.debug(<Error>error, "updateSenderParametersVideo", `Could not set origin ${this._mediaType} video sender parameters.`);
      }
      Log.debug(
        `Sender Parameter Update: Enabled=${this._encodingEnabled}, Bitrate=${encodings[0].maxBitrate}, FrameRate=${encodings[0].maxFramerate}, ResolutionScale=${encodings[0].scaleResolutionDownBy}`
      );
    }
  }

  private async updateSenderParameters(): Promise<void> {
    await this.updateSenderParametersAudio();
    await this.updateSenderParametersVideo();
  }

  private setPreferredAudioCodecsOrigin(audioTransceiver: RTCRtpTransceiver): void {
    if (!this._supportsSetCodecPreferences || !this._redAudioEnabled) {
      return;
    }
    const { codecs } = RTCRtpSender.getCapabilities("audio");
    const redCodecIndex = codecs.findIndex((c) => c.mimeType === "audio/red");
    if (redCodecIndex == -1) {
      Log.info("audio/red codec not supported");
      return;
    }
    const redCodec = codecs[redCodecIndex];
    this._preferredCodec = redCodec;
    codecs.splice(redCodecIndex, 1);
    codecs.unshift(redCodec);
    const transceiver = audioTransceiver || this.connection.getTransceivers().find((x) => x.sender && x.sender.track.kind === "audio");
    transceiver.setCodecPreferences(codecs);
    Log.info(`audio/red codec preference set: ${JSON.stringify(codecs)}`);
  }

  private async updateTransceiverDirections(): Promise<void> {
    let updateOffer = false;
    if (this._media?.audioTrack?.stream) {
      if (this.trySetTransceiverDirection("audio", "sendonly")) updateOffer = true;
    } else {
      if (this.trySetTransceiverDirection("audio", "inactive")) updateOffer = true;
    }
    if (this._media?.videoTrack?.stream) {
      if (this.trySetTransceiverDirection("video", "sendonly")) updateOffer = true;
    } else {
      if (this.trySetTransceiverDirection("video", "inactive")) updateOffer = true;
    }
    if (!updateOffer || this.state == "new" || this.state == "closed") return;
    let offer = (await this.connection.createOffer()).sdp;
    offer = await this.mungeOffer(offer);
    await this.connection.setLocalDescription({
      sdp: offer,
      type: "offer",
    });
    //TODO: use sendRequest once the server honors requests
    this.sendNotification({
      type: "offerUpdated",
      offer: offer,
    });
    this._answerUpdated = new PromiseCompletionSource();
    try {
      let answer = (await this._answerUpdated.promise).answer;
      answer = await this.mungeAnswer(answer);
      await this.connection.setRemoteDescription({
        sdp: answer,
        type: "answer",
      });
      await this.updateSenderParameters();
    } catch (error) {
      if (this._mediaType == "display") return; // display media rejected
      else Log.error(`Couldn't update transceiver directions. ${error}`);
    }
  }

  /** @internal */
  public getSender(trackType: TrackType): RTCRtpSender {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._transceivers.get(trackType).transceiver.sender;
  }

  public setMaxAudio(trackType: TrackType): RTCRtpSender {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._transceivers.get(trackType).transceiver.sender;
  }

  /** @internal */
  public getTransceiver(trackType: TrackType): RTCRtpTransceiver {
    Guard.isNotNullOrUndefined(trackType, "trackType");
    return this._transceivers.get(trackType).transceiver;
  }

  /** @internal */
  public async pauseInternal(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", null);
    await this.tryReplaceSenderTrack("video", null);
  }

  /** @internal */
  public async resumeInternal(): Promise<void> {
    await this.tryReplaceSenderTrack("audio", this._media?.audioTrack?.stream ?? null);
    await this.tryReplaceSenderTrack("video", this._media?.videoTrack?.stream ?? null);
  }

  protected getExcludedIceCandidates(): Array<string> {
    if (this._iceRestarting) {
      return [this._currentCandidateIp];
    }

    return [];
  }

  protected attachEventHandlers(): void {
    super.attachEventHandlers();
    this._mediaChannel.addEventListener("close", this._onMediaChannelClose);
    this._mediaChannel.addEventListener("closing", this._onMediaChannelClosing);
    this._mediaChannel.addEventListener("error", this._onMediaChannelError);
    this._mediaChannel.addEventListener("message", this._onMediaChannelMessage);
    this._mediaChannel.addEventListener("open", this._onMediaChannelOpen);
  }

  protected detachEventHandlers(): void {
    this._media?.stateChanged.unbind(this._onMediaStateChanged);
    this._media?.audioTrack?.streamBound.bind(this._onAudioTrackStreamBound);
    this._media?.audioTrack?.streamUnbound.bind(this._onAudioTrackStreamUnbound);
    this._media?.videoTrack?.streamBound.bind(this._onVideoTrackStreamBound);
    this._media?.videoTrack?.streamUnbound.bind(this._onVideoTrackStreamUnbound);
    this._mediaChannel.removeEventListener("close", this._onMediaChannelClose);
    this._mediaChannel.removeEventListener("closing", this._onMediaChannelClosing);
    this._mediaChannel.removeEventListener("error", this._onMediaChannelError);
    this._mediaChannel.removeEventListener("message", this._onMediaChannelMessage);
    this._mediaChannel.removeEventListener("open", this._onMediaChannelOpen);
    super.detachEventHandlers();
  }

  protected async negotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    return (
      await this._client.negotiate(
        {
          audioLevelInterval: this._audioLevelInterval,
          mediaType: this._mediaType,
          offer: offer,
          replicationCount: this._replicationCount,
        },
        abortSignal
      )
    ).answer;
  }

  protected async onOpened(): Promise<void> {
    await super.onOpened();
    await this.updateMediaBindings();
    await this.updateTransceiverDirections();
    await this.updateSenderParameters();
  }

  protected onTerminated(): void {
    this._answerUpdated?.reject("Connection closed.");
    super.onTerminated();
  }

  protected processNotification(notification: Message): void {
    if (notification.type == "answerUpdated") {
      if (this._answerUpdated) this._answerUpdated.resolve(notification);
    } else if (notification.type == "constraintsUpdated") {
      void this._mediaNotificationQueue.dispatch(async () => {
        await this.updateConstraints(notification.constraints);
      });
    } else if (notification.type == "displayMediaRejected") {
      if (this._answerUpdated) this._answerUpdated.reject(new Error("Media rejected."));
      void this._mediaNotificationQueue.dispatch(async () => {
        await this._mediaRejected.dispatch({
          connection: this,
          media: this._media,
          mediaType: this._mediaType,
        });
      });
    } else if (notification.type == "displayMediaReplaced") {
      void this._mediaNotificationQueue.dispatch(async () => {
        await this._mediaReplaced.dispatch({
          connection: this,
          media: this._media,
          mediaType: this._mediaType,
        });
      });
    } else {
      void this.eventLogger.warning(null, "processNotification", `Unexpected ${notification.type} edge notification.`);
    }
  }

  private async detectDegradation(track: Track): Promise<void> {
    this._iceRestartBackoff--;

    if (!this._customBandwidthAdaptationEnabled) {
      Log.debug("Custom bandwidth adaptation is disabled");
      return;
    }

    if (!this._rampedUp || (Utility.isMobileBrowser() && !this._rampedUpMobile)) {
      Log.debug("Waiting for ramp up to complete");
      return;
    }

    const level = this.getDegradationLevel(this._media.videoTrack);
    const actualPixelCount = track.statVideo.Track_FrameHeight * track.statVideo.Track_FrameWidth;
    const availableBitrate = this._media.videoTrack.availableOutgoingBitrate ?? this._media.videoTrack.targetBitrate;
    const rtt = this._media.videoTrack.roundTripTime;
    let newAudioBitrate = this._clientConstraints.audioBitrateMax;
    let newVideoBitrate = this._clientConstraints.videoBitrateMax;
    let newFrameRate = this._clientConstraints.videoFrameRateMax;
    let newPixelCount = this._clientConstraints.videoPixelCountMax;
    let scale = false;

    const maxAudioBitrate = Math.min(this._maxAudioBitrate, this._serverConstraints.audioBitrateMax);
    const maxVideoBitrate = Math.min(this._maxBitrate, this._serverConstraints.videoBitrateMax);
    const maxFrameRate = Math.min(this._maxFrameRate, this._serverConstraints.videoFrameRateMax);
    const maxPixelCount = Math.min(this._maxPixelCount, this._serverConstraints.videoPixelCountMax);

    if (level === "none") {
      this._healthyChecks++;
      this._unhealthyChecks = 0;
      this._scalingBackoff = this.getScalingBackoff();

      if (this._currentLevel !== "high") {
        if (this._healthyChecks % 5 == 0) {
          const healthyIntervals = this._healthyChecks / 5;
          const bitrateStep = Math.max(this._minBitrateStep, newVideoBitrate * 0.1) * (Utility.isIOS() ? 1 : healthyIntervals);

          // Recover audio first
          if (newAudioBitrate != maxAudioBitrate) {
            newAudioBitrate = maxAudioBitrate;
          } else {
            newVideoBitrate = Math.min(availableBitrate, Math.min(maxVideoBitrate, newVideoBitrate + bitrateStep));

            // Re-enable video once the network starts to recover
            if (newVideoBitrate > this._availableBitrateThreshold) {
              this._encodingEnabled = true;
            }

            // Only recover the framerate if the available bitrate is above 200kbps
            // This prevents premature recovery on very slow/degraded networks and allows for a smoother experience for a longer duration
            if (newVideoBitrate >= this._availableBitrateFrameRateRecoveryThreshold) {
              newFrameRate = Math.min(maxFrameRate, this.sanitizeFrameRate(Math.ceil(newFrameRate + (5 * healthyIntervals)), maxFrameRate));
            }

            let calculatedBitrate = VideoUtility.calculateBitrateUsingPixelCount(Math.min(maxPixelCount, actualPixelCount + this._minPixelCount), newFrameRate);
            let buffer = Utility.isIOS() ? 1 : 1.1;

            // If the target bitrate is greater than the bitrate required for the camera resolution + buffer
            // We want to recover the resolution fast to prevent too many resolution changes and blips on the screen.
            // So every cycle we try to significantly increase the pixel count but only if the newly calculated bitrate + buffer can handle it.
            // Make sure the frame rate is also fully recovered before recovering resolution.
            while (calculatedBitrate * buffer < newVideoBitrate && newPixelCount != maxPixelCount && newFrameRate == maxFrameRate) {
              newPixelCount = Math.min(maxPixelCount, newPixelCount + this._minPixelCount);
              calculatedBitrate = VideoUtility.calculateBitrateUsingPixelCount(Math.min(maxPixelCount, newPixelCount + this._minPixelCount), newFrameRate);
              Log.debug(`Scaling up resolution: NewPixelCount=${newPixelCount}, PreviousPixelCount=${actualPixelCount}`);
            }
          }

          scale = true;
        }
      }
    } else if (this._scalingBackoff <= 0) {
      this._healthyChecks = 0;
      this._unhealthyChecks++;
      this._currentLevel = level;

      if (level === "low") {
        // If the available bitrate is below 100kbps, the network must be really bad so we start sending the absolute minimum.
        // This may improve the experience at the edge of a WiFi network and on very slow/degraded networks
        if (availableBitrate < this._availableBitrateThreshold) {
          newVideoBitrate = this._minBitrate;
          newFrameRate = Math.min(this._minFrameRate, this._minFrameRateSevereDegradation);
        } else {
          // If the bitrate is jumpy and inconsistent due to packet loss, make sure not to set it higher than the current bitrate if we're degraded
          newVideoBitrate = Math.min(newVideoBitrate, Math.min(maxVideoBitrate, Math.max(this._minBitrate, availableBitrate)));
          newFrameRate = Math.min(newFrameRate, this._minFrameRate);
        }

        if (newVideoBitrate == this._minBitrate) {
          newAudioBitrate = this._minAudioBitrate;
        }
      } else if (level === "medium") {
        newVideoBitrate = Math.round(newVideoBitrate / 2);
        newFrameRate = Math.round(newFrameRate * (2 / 3));
      } else if (level === "step") {
        newVideoBitrate = Math.round(newVideoBitrate - Math.max(this._minBitrateStep, newVideoBitrate * 0.1));
        newFrameRate = Math.max(this._minFrameRate, newFrameRate - 3);
      }

      // Don't fall below the minimum
      newVideoBitrate = Math.max(this._minBitrate, newVideoBitrate);
      newFrameRate = Math.max(this._minFrameRate, this.sanitizeFrameRate(newFrameRate, maxFrameRate));

      // Calculate the amount of bandwidth required to send video at the given constraints
      let calculatedBitrate = VideoUtility.calculateBitrateUsingPixelCount(actualPixelCount, newFrameRate);

      // If the bitrate required for the camera resolution is greater than the target bitrate, scale down the resolution
      while (calculatedBitrate > newVideoBitrate && newPixelCount != this._minPixelCount) {
        newPixelCount = Math.max(this._minPixelCount, newPixelCount - this._minPixelCount);
        calculatedBitrate = VideoUtility.calculateBitrateUsingPixelCount(newPixelCount, newFrameRate);
        Log.debug(`Scaling down resolution: NewPixelCount=${newPixelCount}, PreviousPixelCount=${actualPixelCount}`);
      }

      // Don't go above the available and leave a buffer of 15%
      if (newVideoBitrate > availableBitrate) {
        newAudioBitrate = this._minAudioBitrate;
        newVideoBitrate = Math.max(this._minBitrate, availableBitrate * 0.85);
      }

      this._scalingBackoff = this.getScalingBackoff();;
      scale = true;
    } else {
      this._scalingBackoff--;
    }

    // If the network conditions are severely degraded, and we're still attempting to send media and conditions are not improving, disable the media track
    // in an attempt to help the network recover.
    if (newVideoBitrate < this._minBitrate && rtt > this._rttOffThreshold && this._recoveryTime >= this._recoveryThreshold) {
      Log.debug("Disabling video encoding");
      this._encodingEnabled = false;
      this._recoveryTime = 0;
      this._bitrateIncreases = 0;
      this._bitrateSteady = 0;
      scale = true;
    } else if (this.canEnableEncoding()) {
      Log.debug("Enabling video encoding");
      this._encodingEnabled = true;
      this._bitrateIncreases = 0;
      this._bitrateSteady = 0;
      scale = true;
    }

    if (newFrameRate == maxFrameRate && newVideoBitrate == maxVideoBitrate && newPixelCount == maxPixelCount && this._encodingEnabled) {
      this._currentLevel = "high";
    }

    // If RTT is unhealthy at any point, try an ICE restart in an attempt to find a better route and clear things up as long as we don't need to wait for the backoff
    if (this._rttUnhealthyCount > this._iceRestartThreshold && this._iceRestartBackoff <= 0) {
      this._rttUnhealthyCount = 0;
      this._iceRestartBackoff = defaultIceRestartBackoff;
      await this.iceRestart();
    }

    if (this._encodingEnabled) {
      this._recoveryTime++;
    }

    if (scale) {
      this._clientConstraints.audioBitrateMax = newAudioBitrate;
      this._clientConstraints.videoBitrateMax = newVideoBitrate;
      this._clientConstraints.videoFrameRateMax = newFrameRate;
      this._clientConstraints.videoPixelCountMax = newPixelCount;
      this._media.videoTrack.updateDegradationLevels(this.currentLevelVideoBitrate, this.currentLevelFrameRate, this.currentLevelPixelCount);
      this._media.audioTrack.currentLevelBitrate = this.currentLevelAudioBitrate;
      this.eventQueue.dispatch(async () => {
        await this.updateSenderParameters();
      });
    }
  }

  private getDegradationLevel(track: VideoTrack): DegradationLevel {
    const jitter = track.jitter;
    const packetLoss = track.getSenderPacketLoss();
    const packetsSent = track.currentPacketsSent;
    const nacks = track.nackCount;
    const nackPercentage = nacks / packetsSent;
    const plis = track.pliCount;
    const rtt = track.roundTripTime;
    const frameRate = track.frameRate;
    const lastFrameRate = track.lastFrameRate;
    // If the frame rate suddenly drops even though all other conditions are fine, there might still be an issue with sending so we need to send less
    const frameRateDegraded = frameRate / lastFrameRate <= this._frameRateDegradedThreshold;
    const availableBitrate = this._media.videoTrack.availableOutgoingBitrate ?? this._media.videoTrack.targetBitrate;
    const maxBitrate = this._clientConstraints.videoBitrateMax;

    // If the available bitrate suddenly drops by more than 10% (20% for display media) even though all other conditions are fine, 
    // we need to step down. The algorithm will set the new bitrate to the available bitrate
    const bitrateDropModifier = this._mediaType == "display" ? 0.2 : 0.1;
    const bitrateDegraded = availableBitrate < (maxBitrate - (maxBitrate * bitrateDropModifier));
    let level: DegradationLevel = "none";

    // Keep a history of the RTT for the last 10 seconds to get an average
    this._rttHistory.unshift(rtt);

    if (this._rttHistory.length > this._rttHistorySize) {
      this._rttHistory.pop();
    }

    // TODO: Refactor logic into a class and add unit tests

    if (rtt > this._rttOffThreshold) {
      this._rttUnhealthyCount++;
    } else {
      this._rttUnhealthyCount = 0;
    }

    if (
      jitter >= this.getJitterThreshold("low") ||
      packetLoss > this._packetLossHighThreshold ||
      plis >= this._pliThresholdHigh ||
      nackPercentage >= this._nackHighThreshold ||
      rtt >= this._rttHighThreshold
    ) {
      // Scale immediately
      if (this._mediaType === "user") {
        this._scalingBackoff = 0;
      } else {
        this._scalingBackoff -= 1;
      }

      level = "low";
    } else if (
      frameRateDegraded ||
      jitter >= this.getJitterThreshold("medium") ||
      packetLoss >= this._packetLossMediumThreshold ||
      plis >= this._pliThresholdMedium ||
      nackPercentage >= this._nackMediumThreshold ||
      rtt >= this._rttMediumThreshold
    ) {
      // See if we can handle this degradation for a another second
      if (this._mediaType === "user") {
        this._scalingBackoff -= 2;
      }

      level = "medium";
    } else if (
      bitrateDegraded ||
      jitter > this.getJitterThreshold("step") ||
      packetLoss >= this._packetLossLowThreshold ||
      plis >= this._pliThresholdLow ||
      nackPercentage >= this._nackLowThreshold ||
      rtt >= this._rttLowThreshold
    ) {
      if (this._mediaType === "user") {
        level = "step";
      }
    }

    // If the available bitrate dropped below the current max or the framerate suddenly degraded, scale immediately
    if ((bitrateDegraded || frameRateDegraded) && this._mediaType === "user") {
      this._scalingBackoff = 0
    }

    if (level !== "none") {
      Log.debug(`Degradation Stats: Level=${level}, Jitter=${jitter}, PacketLoss=${packetLoss}, NACKs=${nackPercentage}, PLIs=${plis}, RTT=${rtt}, FrameRateDegraded=${frameRateDegraded}, BitrateDegraded=${bitrateDegraded}`);
    }

    return level;
  }

  private getScalingBackoff(): number {
    return this._mediaType == "display" ? defaultScalingBackoffDisplay : defaultScalingBackoffUser;
  }

  /**
   * This method returns a jitter threshold based on if RTT is high or low.
   * The degradation level passed in is the target threshold we want to check for.
   * @param level The target degradation level
   * @returns The jitter threshold to check for
   */
  private getJitterThreshold(level: DegradationLevel): number {
    const rttAverage = this._rttHistory.reduce((total, current) => total + current) / this._rttHistory.length;

    if (rttAverage > this._rttAverageThreshold) {
      if (level === "low") {
        return this._jitterRttHighThreshold;
      } else if (level === "medium") {
        return this._jitterRttMediumThreshold;
      } else {
        return this._jitterRttLowThreshold;
      }
    } else {
      if (level === "low") {
        return this._jitterHighThreshold;
      } else if (level === "medium") {
        return this._jitterMediumThreshold;
      } else {
        return this._jitterLowThreshold;
      }
    }
  }

  private sanitizeFrameRate(frameRate: number, maxFrameRate: number) {
    if (!Utility.isIOS()) {
      return frameRate;
    }

    /* IOS does not respect different frame rates other than 10, 15, and 30
      so we calculate the closest value we should set it to based on incoming frame rate.
      Less frame rate changes on iOS prevent chopiness because the the camera constraints change when the encoder constraints change (theory).
      Ex. frameRate==14: Math.round(14 / 10) = Math.round(1.4) = 1 * 10 = 10: Set to 10 (minimum)
      Ex. frameRate==18: Math.round(18 / 10) = Math.round(1.8) = 2 * 10 = 20: Set to 15 (median)
      Ex. frameRate==22: Math.round(22 / 10) = Math.round(2.2) = 2 * 10 = 20: Set to 15 (median)
      Ex. frameRate==25: Math.round(25 / 10) = Math.round(2.5) = 3 * 10 = 30: Set to 25 (max) 
    */

    const estimatedFrameRate = Math.round(frameRate / 10) * 10;

    if (estimatedFrameRate == this._minFrameRate) {
      return this._minFrameRate;
    } else if (estimatedFrameRate < maxFrameRate) {
      return medianIOSFrameRate;
    } else {
      return maxFrameRate;
    }
  }

  private canEnableEncoding() {
    let bitrateDecreasing = false;
    let bitrateIncreasing = false;

    if (this._media.videoTrack.targetBitrate > 0) {
      bitrateDecreasing = this._media.videoTrack.didTargetBitrateDecrease();
      bitrateIncreasing = this._media.videoTrack.didTargetBitrateIncrease();
    } else if (this._media.videoTrack.availableOutgoingBitrate > 0) {
      bitrateDecreasing = this._media.videoTrack.didAvailableOutgoingBitrateDecrease();
      bitrateIncreasing = this._media.videoTrack.didAvailableOutgoingBitrateIncrease();
    }

    if (!bitrateDecreasing) {
      this._bitrateSteady++;
    } else {
      this._bitrateSteady = 0;
      this._bitrateIncreases = 0;
    }

    if (this._bitrateSteady > this._bitrateSteadyThreshold) {
      this._bitrateSteady = 0;
      bitrateIncreasing = true;
    }

    if (bitrateIncreasing) {
      this._bitrateIncreases++;
    }

    Log.debug(`Bitrate Stats: Steady=${this._bitrateSteady}, Increases=${this._bitrateIncreases}`);

    if (this._bitrateIncreases >= this._bitrateIncreasesThreshold && !this._encodingEnabled) {
      return true;
    }

    return false;
  }

  protected async renegotiate(offer: string, abortSignal?: AbortSignal): Promise<string> {
    return (
      await this._client.renegotiate(
        {
          mediaType: this._mediaType,
          offer: offer,
        },
        abortSignal
      )
    ).answer;
  }

  public setMedia(media: LocalMedia): Promise<void> {
    return this.eventQueue.dispatch(async () => {
      if (this._media == media) return;
      if (media?.connection && media.connection != this) await media.connection.setMedia(null);
      if (this._media) {
        this._media.stateChanged.unbind(this._onMediaStateChanged);
        this._media.audioTrack?.streamBound.unbind(this._onAudioTrackStreamBound);
        this._media.audioTrack?.streamUnbound.unbind(this._onAudioTrackStreamUnbound);
        this._media.videoTrack?.frameSizeChanged.unbind(this._onVideoTrackFrameSizeChanged);
        this._media.videoTrack?.streamBound.unbind(this._onVideoTrackStreamBound);
        this._media.videoTrack?.streamUnbound.unbind(this._onVideoTrackStreamUnbound);
        this._media.connection = null;
      }
      this._media = media;
      if (this._media) {
        this._media.stateChanged.bind(this._onMediaStateChanged);
        this._media.audioTrack?.streamBound.bind(this._onAudioTrackStreamBound);
        this._media.audioTrack?.streamUnbound.bind(this._onAudioTrackStreamUnbound);
        this._media.videoTrack?.frameSizeChanged.bind(this._onVideoTrackFrameSizeChanged);
        this._media.videoTrack?.streamBound.bind(this._onVideoTrackStreamBound);
        this._media.videoTrack?.streamUnbound.bind(this._onVideoTrackStreamUnbound);
        this._media.connection = this;
      }
      await this.updateMediaBindings();
      await this.updateTransceiverDirections();
    });
  }

  public setPriorityAudio(priorityAudio: LocalTrackPriority): Promise<void> {
    Guard.isNotNullOrUndefined(priorityAudio, "priorityAudio");
    return this.eventQueue.dispatch(async () => {
      if (this._priorityAudio == priorityAudio) return;
      this._priorityAudio = priorityAudio;
      await this.updateSenderParametersAudio();
    });
  }

  public setPriorityVideo(priorityVideo: LocalTrackPriority): Promise<void> {
    Guard.isNotNullOrUndefined(priorityVideo, "priorityVideo");
    return this.eventQueue.dispatch(async () => {
      if (this._priorityVideo == priorityVideo) return;
      this._priorityVideo = priorityVideo;
      await this.updateSenderParametersVideo();
    });
  }

  public async updateStats(): Promise<void> {
    if (this.isTerminated) return;
    //TODO: defensive cof
    const senderList = this.connection.getSenders();
    for (const sender of senderList) {
      if (sender.track != null) {
        //pull sender stats
        const statReport = await sender.getStats();
        const pairStat = new StatisticConnection();
        pairStat.AttendeeId = this.attendeeId;

        //first collect pair details
        for (const [key, value] of statReport.entries()) {
          if (value.type == "candidate-pair") {
            pairStat.Pair_Id = value.id;
            pairStat.Pair_Timestamp = value.timestamp;
            pairStat.Pair_BytesReceived = value.bytesReceived;
            pairStat.Pair_BytesSent = value.bytesSent;
            pairStat.Pair_RoundTripTimeCurrent = value.currentRoundTripTime;
            pairStat.Pair_RoundTripTimeTotal = value.totalRoundTripTime;
            pairStat.Pair_AvailableOutgoing = Math.round(value.availableOutgoingBitrate / 1000);
            pairStat.Pair_RequestsReceived = value.requestsReceived;
            pairStat.Pair_RequestsSent = value.requestsSent;
            pairStat.Pair_ResponsesReceived = value.responsesReceived;
            pairStat.Pair_ResponsesSent = value.responsesSent;
            pairStat.Pair_ConsentRequestsSent = value.consentRequestsSent;
            pairStat.Pair_IsNominated = value.nominated;
          }

          if (value.type == "remote-candidate") {
            pairStat.Rmt_Id = value.id;
            pairStat.Rmt_IsRemote = value.isRemote;
            pairStat.Rmt_Ip = value.ip ?? value.address;
            pairStat.Rmt_Port = value.port;
            pairStat.Rmt_Protocol = value.protocol;
            pairStat.Rmt_CandidateType = value.candidateType;
            pairStat.Rmt_IsDeleted = value.deleted;

            if (!pairStat.Rmt_Ip) {
              pairStat.Rmt_Ip = this._currentIceCandidateRemoteIp;
            }
          }

          if (value.type == "local-candidate") {
            pairStat.Lcl_Id = value.id;
            pairStat.Lcl_IsRemote = value.isRemote;
            pairStat.Lcl_Ip = value.ip ?? value.address;
            pairStat.Lcl_Port = value.port;
            pairStat.Lcl_Protocol = value.protocol;
            pairStat.Lcl_CandidateType = value.candidateType;
            pairStat.Lcl_Deleted = value.deleted;
            pairStat.Lcl_NetworkAdapterType = value.networkAdapterType;
            pairStat.Lcl_NetworkType = value.networkType;

            if (!pairStat.Lcl_NetworkType && pairStat.Lcl_Ip) {
              const candidate = this._currentIceCandidates.find(x => x.address == pairStat.Lcl_Ip);

              if (candidate) {
                if (candidate.wifiCandidate) {
                pairStat.Lcl_NetworkAdapterType = "wifi";
                pairStat.Lcl_NetworkType = "wifi";
                } else if (candidate.lanCandidate) {
                  pairStat.Lcl_NetworkAdapterType = "lan";
                  pairStat.Lcl_NetworkType = "lan";
                } else {
                  pairStat.Lcl_NetworkAdapterType = "cellular";
                  pairStat.Lcl_NetworkType = "cellular";
                }
              }
            }

            if (sender.track.kind == "video") {
              if (this._currentCandidateIp) {
                if (this._currentCandidateIp != pairStat.Lcl_Ip || this._currentCandidateNetworkType != pairStat.Lcl_NetworkType) {
                  // TODO: ICE candidate changed
                }
              }

              this._currentCandidateIp = pairStat.Lcl_Ip;
              this._currentCandidateNetworkAdapterType = pairStat.Lcl_NetworkAdapterType;
              this._currentCandidateNetworkType = pairStat.Lcl_NetworkType;
            }
          }
        } //each entry

        if (sender.track.kind == "audio") {
          const audioStat = new StatisticAudio();
          audioStat.AttendeeId = this.attendeeId;
          //NOTE: on server: Attendee.DeviceId;
          audioStat.MediaType = this._mediaType;
          audioStat.CreatedOn = new Date();

          audioStat.TrackIndex = 0;
          audioStat.IsClient = true;

          audioStat.IsMuted = !sender.track.enabled;
          //? audioStat.IsNoiseSuppressed = _isAudioNoiseSuppressed;
          //? audioStat.IsPaused = _isAudioPaused;

          audioStat.BitrateConstraint = this.audioBitrateMax;

          //TODO: BitrateAllocation, BitrateServer
          //FramerateActual, FramerateConstraint, FramerateEstimated, FramerateServer

          for (const [key, value] of statReport.entries()) {
            if (value.type == "media-source") {
              audioStat.Src_AudioLevel = value.audioLevel;
              audioStat.Src_TotalAudioEnergy = value.totalAudioEnergy;
              audioStat.Src_TotalSamplesDuration = value.totalSamplesDuration;

              if (audioStat.Src_AudioLevel != null) {
                this._media?.audioTrack?.updateLevel(audioStat.Src_AudioLevel);
              }
            }

            if (value.type == "codec") {
              audioStat.Codec_MimeType = value.mimeType;
              audioStat.Codec_Channels = value.channels;
              audioStat.Codec_ClockRate = value.clockRate;
            }

            if (value.type == "outbound-rtp") {
              audioStat.Rtp_Kind = value.kind;
              audioStat.Rtp_SSRC = value.ssrc;
              audioStat.TrackIdentifier = value.mediaSourceId;
              audioStat.TrackMid = value.mid;

              audioStat.Rtp_PacketsSent = value.packetsSent;
              audioStat.Rtp_RetransmittedPacketsSent = value.retransmittedPacketsSent;
              audioStat.Rtp_BytesSent = value.bytesSent;
              audioStat.Rtp_HeaderBytesSent = value.headerBytesSent;
              audioStat.Rtp_RetransmittedBytesSent = value.retransmittedBytesSent;

              audioStat.Rtp_TotalPacketSendDelay = value.totalPacketSendDelay;
              audioStat.Rtp_NackCount = value.nackCount;

              audioStat.BitrateEstimated = Math.round(value.targetBitrate / 1000);
              audioStat.BitrateActual = this._bitrateAudio.calculate(audioStat.Rtp_BytesSent, value.timestamp);
            }

            if (value.type == "remote-inbound-rtp") {
              audioStat.Rtp_Timestamp = value.timestamp as DOMHighResTimeStamp;
              audioStat.Rtp_PacketsLost = value.packetsLost;
              audioStat.Rtp_Jitter = Math.round(value.jitter * 1000);
              audioStat.Rtp_RoundTripTime = Math.round(value.roundTripTime * 1000);
              audioStat.Rtp_RoundTripTimeMeasurements = value.roundTripTimeMeasurements;
              audioStat.Rtp_RoundTripTimeTotal = value.totalRoundTripTime;
            }

            if (value.type == "transport") {
              audioStat.Transport_Timestamp = value.timestamp as DOMHighResTimeStamp;
              audioStat.Transport_BytesSent = value.bytesSent;
              audioStat.Transport_BytesReceived = value.bytesReceived;
              audioStat.Transport_PacketsSent = value.packetsSent;
              audioStat.Transport_PacketsReceived = value.packetsReceived;
              audioStat.Transport_State = value.dtlsState;
            }
          } //foreach entry

          //only save the stat every polling interval
          if (this._currentStatCount % this._statsPollingInterval === 0) {
            const sectionsAudio = [];
            for (const stats of statReport.values()) {
              if (stats.type != "certificate" && stats.type != "candidate-pair" && stats.type != "local-candidate" && stats.type != "remote-candidate") {
                //NOTE: debugging only, makes huge results
                //sectionsVideo.push(stats);
                sectionsAudio.push(stats.type);
              }
            }
            audioStat.Json = JSON.stringify(sectionsAudio, null, 2);

            this._statsBatchAudio.push(audioStat);
          }
          this._media.audioTrack.updateStats(audioStat, pairStat);
          const track: Track = this._transceivers.get("audio");

          if (track) {
            track.statAudio = audioStat;
          }
        } //audio

        if (sender.track.kind == "video") {
          const videoStat = new StatisticVideo();
          videoStat.AttendeeId = this.attendeeId;
          //NOTE: on server: Attendee.DeviceId;
          videoStat.MediaType = this._mediaType;
          videoStat.CreatedOn = new Date();

          videoStat.TrackIndex = 0;
          videoStat.SpatialLayerIndex = 0; //only stats for the incoming
          videoStat.TemporalLayerIndex = 0;

          videoStat.IsClient = true;
          videoStat.IsMuted = !sender.track.enabled;
          //? videoStat.IsPaused = ;
          //? videoStat.IsDisabled = ;

          videoStat.BitrateConstraint = Math.round(this._clientConstraints.videoBitrateMax);
          videoStat.FramerateConstraint = Math.round(this._clientConstraints.videoFrameRateMax);
          videoStat.PixelCountConstraint = Math.round(this._clientConstraints.videoPixelCountMax);
          videoStat.BitrateEstimated = Math.round(this._estimatedConstraints.videoBitrateMax);
          videoStat.FramerateEstimated = Math.round(this._estimatedConstraints.videoFrameRateMax);
          videoStat.PixelCountEstimated = Math.round(this._estimatedConstraints.videoPixelCountMax);
          videoStat.BitrateServer = Math.round(this._serverConstraints.videoBitrateMax);
          videoStat.FramerateServer = Math.round(this._serverConstraints.videoFrameRateMax);
          videoStat.PixelCountServer = Math.round(this._serverConstraints.videoPixelCountMax);

          videoStat.BitrateAllocation = pairStat.Pair_AvailableOutgoing;

          for (const [key, value] of statReport.entries()) {
            if (value.type == "codec") {
              videoStat.Codec_MimeType = value.mimeType;
              videoStat.Codec_Channels = value.channels;
              videoStat.Codec_ClockRate = value.clockRate;
            }

            if (value.type == "outbound-rtp") {
              videoStat.Rtp_Kind = value.kind;
              videoStat.Rtp_SSRC = value.ssrc;
              videoStat.Rtp_IsRemote = value.isRemote;
              videoStat.TrackIdentifier = value.mediaSourceId;
              videoStat.TrackMid = value.mid;

              videoStat.Rtp_FirCount = value.firCount;
              videoStat.Rtp_PliCount = value.pliCount;
              videoStat.Rtp_NackCount = value.nackCount;

              videoStat.Track_FrameWidth = value.frameWidth;
              videoStat.Track_FrameHeight = value.frameHeight;

              /*
              if (videoStat.ResolutionHeight == null) videoStat.ResolutionHeight = value.frameHeight;
              if (videoStat.ResolutionWidth == null) videoStat.ResolutionWidth = value.frameWidth;
              */

              videoStat.Rtp_PacketsSent = value.packetsSent;
              videoStat.Rtp_RetransmittedPacketsSent = value.retransmittedPacketsSent;
              videoStat.Rtp_BytesSent = value.bytesSent;
              videoStat.Rtp_HeaderBytesSent = value.headerBytesSent;
              videoStat.Rtp_RetransmittedBytesSent = value.retransmittedBytesSent;
              videoStat.Rtp_FramesEncoded = value.framesEncoded;
              videoStat.Rtp_KeyFramesEncoded = value.keyFramesEncoded;
              videoStat.Rtp_TotalEncodeTime = value.totalEncodeTime;
              videoStat.Rtp_TotalEncodedBytesTarget = value.totalEncodedBytesTarget;
              videoStat.Rtp_TotalPacketSendDelay = value.totalPacketSendDelay;
              videoStat.Rtp_FramesPerSecond = value.framesPerSecond;
              videoStat.Rtp_FramesSent = value.framesSent;
              videoStat.Rtp_HugeFramesSent = value.hugeFramesSent;
              videoStat.Rtp_QualityLimitationChanges = value.qualityLimitationResolutionChanges;
              videoStat.Rtp_QualityLimitationReason = value.qualityLimitationReason;
              //TODO: escape or encode, breaks deserialization
              //videoStat.Rtp_QualityLimitationDurations = value.qualityLimitationDurations;
              videoStat.Rtp_EncoderImplementation = value.encoderImplementation;
              videoStat.Rtp_QpSum = value.qpSum;
              videoStat.Rtp_IsPowerEfficient = value.powerEfficientEncoder;
              videoStat.Rtp_ScalabilityMode = value.scalabilityMode;

              videoStat.Rtp_TargetBitrate = Math.round(value.targetBitrate / 1000);
              videoStat.BitrateEstimated = Math.round(value.targetBitrate / 1000);
              videoStat.BitrateActual = this._bitrateVideo.calculate(videoStat.Rtp_BytesSent, value.timestamp);

              if (videoStat.Rtp_FramesPerSecond > 0) {
                videoStat.FramerateActual = videoStat.Rtp_FramesPerSecond;
              } else {
                videoStat.FramerateActual = this._framerateVideo.calculate(videoStat.Rtp_FramesSent, value.timestamp);
              }
            }

            if (value.type == "remote-inbound-rtp") {
              videoStat.Rtp_Timestamp = value.timestamp as DOMHighResTimeStamp;
              videoStat.Rtp_PacketsLost = value.packetsLost;
              videoStat.Rtp_Jitter = Math.round(value.jitter * 1000);
              videoStat.Rtp_RoundTripTime = Math.round(value.roundTripTime * 1000);
              videoStat.Rtp_RoundTripTimeMeasurements = value.roundTripTimeMeasurements;
              videoStat.Rtp_RoundTripTimeTotal = value.totalRoundTripTime;
            }

            if (value.type == "media-source") {
              videoStat.Src_FramesPerSecond = value.framesPerSecond;
              videoStat.Src_Frames = value.frames;
              if (videoStat.ResolutionHeight == null) videoStat.ResolutionHeight = value.height;
              if (videoStat.ResolutionWidth == null) videoStat.ResolutionWidth = value.width;
            }

            if (value.type == "transport") {
              videoStat.Transport_Timestamp = value.timestamp as DOMHighResTimeStamp;
              videoStat.Transport_BytesSent = value.bytesSent;
              videoStat.Transport_BytesReceived = value.bytesReceived;
              videoStat.Transport_PacketsSent = value.packetsSent;
              videoStat.Transport_PacketsReceived = value.packetsReceived;
              videoStat.Transport_State = value.dtlsState;
            }
          } //foreach entry

          //aggregations
          if (videoStat.ResolutionHeight == null) {
            videoStat.ResolutionHeight = videoStat.Track_FrameHeight;
          }
          if (videoStat.ResolutionWidth == null) {
            videoStat.ResolutionWidth = videoStat.Track_FrameWidth;
          }
          if (videoStat.Track_FrameHeight != null && videoStat.Track_FrameWidth != null) {
            videoStat.PixelCountActual = videoStat.Track_FrameHeight * videoStat.Track_FrameWidth;
          }
          if (videoStat.ResolutionHeight != null && videoStat.ResolutionWidth != null) {
            videoStat.PixelCountEstimated = videoStat.ResolutionHeight * videoStat.ResolutionWidth;
          }

          //only save the stat every polling interval
          if (this._currentStatCount % this._statsPollingInterval === 0) {
            const sectionsVideo = [];
            for (const stats of statReport.values()) {
              if (stats.type != "certificate" && stats.type != "candidate-pair" && stats.type != "local-candidate" && stats.type != "remote-candidate") {
                //NOTE: debugging only, makes huge results
                //sectionsVideo.push(stats);
                sectionsVideo.push(stats.type);
              }
            }
            videoStat.Json = JSON.stringify(sectionsVideo, null, 2);

            this._statsBatchVideo.push(videoStat);
          }
          this._media.videoTrack.updateStats(videoStat, pairStat);

          const track: Track = this._transceivers.get("video");

          if (track) {
            if (videoStat.Rtp_BytesSent > 0) {
              this._currentRampUpSeconds++;
              const availableBitrate = pairStat.Pair_AvailableOutgoing ?? videoStat.Rtp_TargetBitrate;

              // If the available bitrate is lower than the current constraint, set ramped up to be true so that any degradation logic can proceed
              this._rampedUp = this._currentRampUpSeconds >= this._rampUpSeconds || availableBitrate < videoStat.BitrateConstraint;
              this._rampedUpMobile = this._currentRampUpSeconds >= this._rampUpSecondsMobile || availableBitrate < videoStat.BitrateConstraint;
            }

            track.statVideo = videoStat;
            await this.detectDegradation(track);
          }
        } //video
      } //has track
    } //foreach sender

    this._currentStatCount++;

    //TODO: exception handling and timeout
    if (this._currentStatCount % this._statsSendingInterval === 0) {
      try {
        const batchAudio = this._statsBatchAudio.splice(0);
        const batchVideo = this._statsBatchVideo.splice(0);
        this.sendNotification({
          type: "clientStats",
          audioStats: batchAudio,
          videoStats: batchVideo,
        });
      } catch (err) {
        void this.eventLogger.error(err as Error, "sendOriginClientStats", (err as Error).message, null);
      }
    }
  } //updateStats
}
