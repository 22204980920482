import AudioTrack from "./AudioTrack";
import AudioTrackEvent from "./models/AudioTrackEvent";
import DevicesEvent from "./models/DevicesEvent";
import DeviceManager from "./DeviceManager";
import DisplayAudioTrack from "./DisplayAudioTrack";
import DocumentObserver from "./DocumentObserver";
import DocumentObserverEvent from "./models/DocumentObserverEvent";
import ElementObserver from "./ElementObserver";
import ElementObserverEvent from "./models/ElementObserverEvent";
import EventOwner from "../core/EventOwner";
import EventOwnerAsync from "../core/EventOwnerAsync";
import Guard from "../core/Guard";
import Log from "../logging/Log";
import Reactive from "../core/Reactive";
import RemoteAudioTrackEvent from "./models/RemoteAudioTrackEvent";
import RemoteMedia from "./RemoteMedia";
import UserAudioTrack from "./UserAudioTrack";

export default class RemoteAudioTrack extends AudioTrack {
  private static _canRequestDeviceId: boolean;

  public static get canRequestDeviceId(): boolean { return this._canRequestDeviceId; }
  public static set canRequestDeviceId(value: boolean) { this._canRequestDeviceId = value; }

  private readonly _onDevicesUpdated: (e: DevicesEvent) => Promise<void>;
  private readonly _onDocumentObserverUpdated: (e: DocumentObserverEvent) => void;
  private readonly _onElementObserverUpdated: (e: ElementObserverEvent) => void;
  private readonly _media: RemoteMedia;
  private readonly _statusUpdated = new EventOwnerAsync<RemoteAudioTrackEvent>();
  private readonly _streamBound = new EventOwnerAsync<AudioTrackEvent>();
  private readonly _streamUnbound = new EventOwner<AudioTrackEvent>();
  private readonly _unsupportedMessage = "The current platform does not support audio output device selection.";

  private _deviceId: string = null;
  private _requestedDeviceId: string = null;
  private _sinkId: string = null;
  private _stream: MediaStreamTrack = null;
  private _targetSinkId: string = null;

  public get deviceId(): string { return this._deviceId; }
  public get isMuted(): boolean { return (this._media.type == "user" && this._media.attendee?.isAudioMuted) ?? false; }
  public get isPaused(): boolean { return (this._media.type == "user" && this._media.attendee?.isAudioPaused) ?? false; }
  public get isRemote(): boolean { return true; }
  public get media(): RemoteMedia { return this._media; }
  public get requestedDeviceId(): string { return this._requestedDeviceId; }
  public get stream(): MediaStreamTrack { return this._stream; }
  
  /** @event */
  public get statusUpdated(): EventOwnerAsync<RemoteAudioTrackEvent> { return this._statusUpdated; }
  /** @event */
  public get streamBound(): EventOwnerAsync<AudioTrackEvent> { return this._streamBound; }
  /** @event */
  public get streamUnbound(): EventOwner<AudioTrackEvent> { return this._streamUnbound; }

  static {
    this._canRequestDeviceId = true;
    this._canRequestDeviceId &&= !!((globalThis.document?.createElement("audio") as any)?.setSinkId);
    this._canRequestDeviceId &&= !!((globalThis.document?.createElement("video") as any)?.setSinkId);
  }

  /** @internal */
  public constructor(media: RemoteMedia) {
    super();
    this._media = media;
    if (!RemoteAudioTrack.canRequestDeviceId) return;
    this._onDevicesUpdated = this.onDevicesUpdated.bind(Reactive.wrap(this));
    this._onDocumentObserverUpdated = this.onDocumentObserverUpdated.bind(Reactive.wrap(this));
    this._onElementObserverUpdated = this.onElementObserverUpdated.bind(Reactive.wrap(this));
  }

  private async bindStream(stream: MediaStreamTrack): Promise<void> {
    Guard.isNotNullOrUndefined(stream, "stream");
    this.prepareStream(stream);
    this._stream = stream;
    this._stream.enabled = !this.isMuted;
    await this._streamBound.dispatch({
      track: this
    });
  }

  private getSinkId(): string {
    const audioOutputDevices = DeviceManager.shared.audioOutputs;
    if (audioOutputDevices.length == 0) return null;
    for (const audioOutputDevice of audioOutputDevices) {
      if (audioOutputDevice.id == this._requestedDeviceId) return audioOutputDevice.id;
    }
    return audioOutputDevices[0].id;
  }

  private async onDevicesUpdated(e: DevicesEvent): Promise<void> {
    if (this._deviceId == "default" || e.removed.find(d => d.id == this._deviceId) || e.added.find(d => d.id == this._requestedDeviceId)) {
      this._deviceId = null;
      await this.updateMediaElements();
      this.updateDeviceId();
    }
  }

  private onDocumentObserverUpdated(e: DocumentObserverEvent): void {
    for (const audioElement of e.audio.added) this.startObserving(audioElement);
    for (const videoElement of e.video.added) this.startObserving(videoElement);
    for (const audioElement of e.audio.removed) this.stopObserving(audioElement);
    for (const videoElement of e.video.removed) this.stopObserving(videoElement);
    void (async () => {
      await this.updateMediaElements();
      this.updateDeviceId();
    })();
  }

  private onElementObserverUpdated(e: ElementObserverEvent): void {
    if (e.element.srcObject != this._media.stream) return;
    void this.tryUpdateMediaElement(e.element);
  }

  private startObserving(element: HTMLMediaElement): void {
    const elementObserver = new ElementObserver(element);
    elementObserver.updated.bind(this._onElementObserverUpdated);
    elementObserver.start();
    this.onElementObserverUpdated({ element });
    (element as any).__observer = elementObserver;
  }

  private stopObserving(element: HTMLMediaElement): void {
    const elementObserver = (element as any).__observer as ElementObserver;
    elementObserver?.stop();
    delete (element as any).__observer;
  }

  private async tryUpdateMediaElement(element: HTMLMediaElement): Promise<boolean> {
    const currentSinkId = (element as any).sinkId ?? "default";
    if (currentSinkId == this._targetSinkId) return true;
    try {
      await (element as any).setSinkId(this._targetSinkId);
      return true;
    } catch (error: any) {
      Log.info(`Could not set ${element.nodeName.toLowerCase()} element sink ID to ${this._targetSinkId}.`, <Error>error);
      return false;
    }
  }

  private unbindStream(): void {
    this._stream = null;
    this._streamUnbound.dispatch({
      track: this
    });
  }

  private updateDeviceId(): void {
    this._deviceId = this._sinkId;
  }

  private async updateMediaElements(): Promise<void> {
    this._sinkId = null;
    this._targetSinkId = this.getSinkId();
    if (this._targetSinkId == null) return;

    // Update all audio and video elements with the updated sinkId.
    // Note: There's a potential edge case here. Since all audio/video elements on the page are updated, an element rendering something other than liveswitch streams are also switched.
    // I can't think of a reason why you'd want different sink ids for the webrtc elements vs other elements, but if that use-case exists, you could implement an ignore list and filter out those elements. 
    const promises = [...DocumentObserver.shared.audioElements, ...DocumentObserver.shared.videoElements]
      .map(element => this.tryUpdateMediaElement(element));
    const results = await Promise.all(promises);
    if (results.filter(result => !result).length) return;
    this._sinkId = this._targetSinkId;
  }

  /**
   * For internal testing only.
   */
  /** @internal */
  public getElementObserver(element: HTMLMediaElement): ElementObserver {
    return (element as any).__observer;
  }

  /** @internal */
  public processStatusUpdated(): Promise<void> {
    return this._statusUpdated.dispatch({
      track: this,
    });
  }

  /** @internal */
  public async start(stream: MediaStreamTrack): Promise<void> {
    await this.onStarting();
    await this.bindStream(stream);
    await this.onStarted();
  }

  /** @internal */
  public stop(): void {
    this.onStopping();
    this.unbindStream();
    this.onStopped();
  }

  protected async onStarted(): Promise<void> {
    if (!RemoteAudioTrack.canRequestDeviceId) return;
    DeviceManager.shared.audioOutputsUpdated.bind(this._onDevicesUpdated);
    DocumentObserver.shared.updated.bind(this._onDocumentObserverUpdated);
    await this.updateMediaElements();
    this.updateDeviceId();
  }

  protected async onStarting(): Promise<void> {
    if (!RemoteAudioTrack.canRequestDeviceId) return;
    await DeviceManager.shared.start();
    DocumentObserver.shared.start();
  }

  protected onStopped(): void { }

  protected onStopping(): void {
    if (!RemoteAudioTrack.canRequestDeviceId) return;
    DocumentObserver.shared.updated.unbind(this._onDocumentObserverUpdated);
    DeviceManager.shared.audioOutputsUpdated.unbind(this._onDevicesUpdated);
  }

  protected prepareStream(stream: MediaStreamTrack): void {
    if (this._media.type == "display") {
      try {
        if ("contentHint" in stream) stream.contentHint = DisplayAudioTrack.contentHint;
      } catch { /* best effort */ }
    } else {
      try {
        if ("contentHint" in stream) stream.contentHint = UserAudioTrack.contentHint;
      } catch { /* best effort */ }
    }
  }

  public async setDevice(deviceId?: string): Promise<void> {
    if (!RemoteAudioTrack.canRequestDeviceId) {
      Log.warn(this._unsupportedMessage);
      return;
    }
    this._deviceId = null;
    this._requestedDeviceId = deviceId ?? null;
    await this.updateMediaElements();
    this.updateDeviceId();
  }

  public async useNextDevice(manager?: DeviceManager): Promise<void> {
    if (!RemoteAudioTrack.canRequestDeviceId) throw new Error(this._unsupportedMessage);
    manager ??= DeviceManager.shared;
    await manager.start();
    const currentDeviceId = this._deviceId ?? this._requestedDeviceId;
    const device = manager.audioOutputs.next(currentDeviceId);
    if (device == null || device.id == currentDeviceId) return;
    await this.setDevice(device.id);
  }

  public async usePreviousDevice(manager?: DeviceManager): Promise<void> {
    if (!RemoteAudioTrack.canRequestDeviceId) throw new Error(this._unsupportedMessage);
    manager ??= DeviceManager.shared;
    await manager.start();
    const currentDeviceId = this._deviceId ?? this._requestedDeviceId;
    const device = manager.audioOutputs.previous(currentDeviceId);
    if (device == null || device.id == currentDeviceId) return;
    await this.setDevice(device.id);
  }
}
