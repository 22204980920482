import jwtDecode from "jwt-decode";
import HttpTokenPayload from "./models/HttpTokenPayload";
import ParseError from "./ParseError";

export default class Utility {
  private static _regexIso8601 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(([+-]\d{2}:\d{2})|Z)$/;

  public static blobToDataUrl(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  public static delay(delay: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(null);
      }, delay);
    });
  }

  public static emptyGuid(): string {
    return "00000000-0000-0000-0000-000000000000";
  }

  /**
   * 
   * @param input The input string from which to extract values.
   * @param pattern The regular expression pattern containing a capture group.
   * @returns An array of strings representing the values of the specified capture group in all matches.
   */
  public static extractCaptureGroups(input: string, pattern: RegExp): string[] {
    const result: string[] = [];
    let match;

    while ((match = pattern.exec(input)) !== null) {
        if (match.length > 1) {
            // match[1] is the first capture group, match[0] would be the entire match
            result.push(match[1]);
        }
    }

    return result;
  }

  public static generateGuid(): string {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      ((c as any) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> (c as any) / 4).toString(16)
    );
  }

  public static getTokenPayload(token: string): HttpTokenPayload | null {
    try {
      const tokenPayload = jwtDecode<{
        attendeeId: string,
        clusterId: string,
        deviceId: string,
        meetingId: string,
      }>(token);
      return <HttpTokenPayload>{
        attendeeId: tokenPayload.attendeeId,
        clusterId: tokenPayload.clusterId,
        deviceId: tokenPayload.deviceId,
        meetingId: tokenPayload.meetingId,
      };
    } catch { /* best effort */ }
    return null;
  }

  public static isArray(value: any): boolean {
    return Array.isArray(value);
  }

  public static isBoolean(value: any): boolean {
    return typeof value == "boolean" || value instanceof Boolean;
  }

  public static isFakeAttendeeId(attendeeId: string): boolean {
    return attendeeId.startsWith("f0000000-0000-0000-0000");
  }

  public static isIso8601(value: string): boolean {
    return this._regexIso8601.test(value);
  }

  public static isNumber(value: any): boolean {
    return typeof value == "number" || value instanceof Number;
  }

  public static isNullOrUndefined(value: any): boolean {
    return (value == null || value == undefined);
  }

  public static isNodeProcess() {
    return typeof((globalThis as any).process) === 'object';
  }

  public static isReplicatedAttendeeId(attendeeId: string): boolean {
    return attendeeId.startsWith("e0000000-0000-0000");
  }

  public static isString(value: any): boolean {
    return typeof value == "string" || value instanceof String;
  }

  public static isMobileBrowser(): boolean {
    const agent = navigator.userAgent;
    let mobile = /Android|iPhone|iPad|iPod|Mobile/i.test(agent) || this.isIOS() || this.isTablet();

    if (/X11; Linux/.test(agent) && this.isTouch()) {
      mobile = true;
    }

    return mobile;
  }

  public static isIOS(): boolean {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || this.isIpad()
  }

  public static isIpad(): boolean {
    return /iPad/i.test(navigator.userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  public static isTablet(): boolean {
    return /iPad/i.test(navigator.userAgent) || window.matchMedia(
      "only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)"
    ).matches
  }

  public static isMobileSafari() {
    return this.isMobileBrowser() && this.isSafari();
  }

  public static isTouch() {
    return (('ontouchstart' in window) || ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0));
  }

  public static isSafari() {
    return /Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent);
  }

  public static parseGuid(buffer: Uint8Array): string {
    if (Utility.isNullOrUndefined(buffer)) throw new Error(`Buffer cannot be null or undefined.`);
    if (buffer.byteLength != 16) throw new Error(`Buffer must have exactly 16 bytes. Buffer has ${buffer.byteLength} byte(s).`);
    const s: string[] = [];
    for (let i = 0; i < buffer.byteLength; i++) s.push(buffer[i].toString(16).padStart(2, "0"));
    return `${s[0]}${s[1]}${s[2]}${s[3]}-${s[4]}${s[5]}-${s[6]}${s[7]}-${s[8]}${s[9]}-${s[10]}${s[11]}${s[12]}${s[13]}${s[14]}${s[15]}`;
  }

  public static parseJson(text: string): any {
    try {
      return JSON.parse(text, (_, value) => {
        if (this.isString(value) && this.isIso8601(value)) return new Date(value);
        return value;
      });
    } catch (error: any) {
      throw new ParseError("Could not parse JSON.", this.sanitizeError(error), text);
    }
  }

  public static sanitizeError(error?: Error): Error {
    if (this.isNullOrUndefined(error)) return error;
    if (error instanceof Error) return error;
    return new Error(error);
  }

  public static async time<T>(task: () => Promise<T>): Promise<[duration: number, result: T]> {
    const startMillis = performance.now();
    const result = await task();
    const duration = Math.round(performance.now() - startMillis);
    return [duration, result];
  }
}
